/**-------------------------------------------------------------
1.2.4.1 EV쿠폰 메인화면_3 - 쿠폰미등록
-------------------------------------------------------------
> http://basic.pointweb.kr/charge/public/html/public/coupon.html
-------------------------------------------------------------*/

/* eslint-disable */


// 기본
import React, { useRef, useState, useEffect, useCallback, ChangeEvent } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { tmapApi } from '../../libs/tmap-api';
import { RouteComponentProps, Link } from 'react-router-dom';
import { useHistory } from "react-router";
import { appActions, store } from '../../store';

//헤더 
import { Header } from '../../components/Layout';
import { tmapInterface } from '../../libs/tmap-interface';
import { globalVars } from '../../libs/global-vars';
import { HandleBrowserBackButton } from '../../components/Layout'

//Axios
import axios from 'axios';
import Const from '../../store/ev'
import { AccessKey } from '../../components/DebugTools/Panel/Items';
import { stringify } from 'qs';

//Mixpanel
import { logbox, mixpanel } from '../../libs/logbox-mixpanel';
import { postCoupon } from '../../apis/coupon';
import { getUser, postUser } from '../../apis/user';
import { modal } from '../../components/GlobalModal';

let scrollCache = 0;

let isExit = false;
let timer: Nullable<number> = null;
let couponCode: any = {};
let userData: any = {};    // 회원정보조회(단건)
let payLoad: any = {};

function CouponRegister(props: RouteComponentProps<{ id: string, productType: string }>) {
    const history = useHistory();
    const [couponList, setCouponList] = useState([{ couponCode: "" }]);
    const [isDisabled, setIsDisabled] = useState(true); // 선택한 쿠폰 적용하기 버튼
    const [inited] = useState(false);
    const [pop, setPop] = useState(false); //쿠폰 등록 팝업
    const [pop2, setPop2] = useState(false); //쿠폰 등록 팝업
    const [popContent, setPopContent] = useState(""); //쿠폰 등록 에러 팝업
    const [sendCouponCode, setSendCouponCode] = useState(""); //쿠폰 코드 값

    const [couponType, setCouponType] = useState(0);

    const onChange = (e: ChangeEvent<HTMLInputElement>, index: number) => {
        const { name, value } = e.target;
        const list = [...couponList];
        list[index]["couponCode"] = value;
        if (value.length > 0) {
            setIsDisabled(false);
            setSendCouponCode(value);
        } else {
            setIsDisabled(true);
        }
        setCouponList(list);
    };

    useEffect(() => {
        mixpanel("evcharge:/evcharge/addcoupon");
        logbox("/evcharge/addcoupon", "");
    }, [])

    // handle click event of the Add button
    const nextId = useRef(1);
    const handleAddClick = () => {
        const coupon = {
            couponCode: ""
        }
        setCouponList([...couponList, coupon]);
        nextId.current += 1;
    };

    // couponList로 인해 젠킨스 빌드 시 오류 발생. 확인 필요
    // 쿠폰등록 팝업
    const registerCoupon = () => {
        
        mixpanel("evcharge:/evcharge/addcoupon:tap.add");
        logbox("/evcharge/addcoupon", "tap.add");

        setIsDisabled(true);

        var axiosApi = require('axios');

        var data = {
            productCode: sendCouponCode,
        };

        postCoupon(data)
            .then(function (response: any) {
                // if(Const.DBG_OK) {
                //     alert('쿠폰번호 \n'+ JSON.stringify(response));	
                // }

                if (response.result) { // 정상적으로 쿠폰 추가
                    mixpanel("evcharge:/evcharge/addcoupon:view.successcoupon_popup");
                    logbox("/evcharge/addcoupon", "tap.successcoupon_popup");

                    modal.alert(
                        () => {
                            setCouponType(response.couponType);
                        },
                        '쿠폰이 등록되었습니다.',
                    );
                } else {
                    mixpanel("evcharge:/evcharge/addcoupon:view.wrongcoupon_popup");
                    logbox("/evcharge/addcoupon", "view.wrongcoupon_popup");
                    
                    modal.alert(
                        () => registPop("wrong"),
                        '유효하지 않은 쿠폰 번호입니다.',
                    );
                }
            })
            .catch(function (error: any) {
                switch (error?.response?.data?.reason) {
                    case "EV-PRODUCT-VALIDATION-FAIL-001":
                        mixpanel("evcharge:/evcharge/addcoupon:view.registcoupon_popup");
                        logbox("/evcharge/addcoupon", "view.registcoupon_popup");

                        modal.alert(
                            () => registPop("regist"),
                            '이미 등록된 쿠폰입니다.',
                        );
                        break;
                    case "EV-PRODUCT-DATE-INVALID-001":
                        mixpanel("evcharge:/evcharge/addcoupon:view.dateendcoupon_popup");
                        logbox("/evcharge/addcoupon", "view.dateendcoupon_popup");

                        modal.alert(
                            () => registPop("end"),
                            '등록 유효기간이 만료된 쿠폰입니다.',
                        );
                        break;
                    case "EV-PRODUCT-VALIDATION-FAIL-002":
                        mixpanel("evcharge:/evcharge/addcoupon:view.wrongcoupon_popup");
                        logbox("/evcharge/addcoupon", "view.wrongcoupon_popup");

                        modal.alert(
                            () => registPop("wrong"),
                            '유효하지 않은 쿠폰 번호입니다.',
                        );
                        break;
                    case "EV-ESS-REQ-FAIL-001":
                        mixpanel("evcharge:/evcharge/addcoupon:view.wrongcoupon_popup");
                        logbox("/evcharge/addcoupon", "view.wrongcoupon_popup");

                        modal.alert(
                            () => registPop("wrong"),
                            '유효하지 않은 쿠폰 번호입니다.',
                        );
                        break;
                    case "EV-PRODUCT-11ST-FAIL-002":
                        mixpanel("evcharge:/evcharge/addcoupon:view.wrongcoupon_popup");
                        logbox("/evcharge/addcoupon", "view.wrongcoupon_popup");

                        modal.alert(
                            () => registPop("wrong"),
                            '유효하지 않은 쿠폰 번호입니다.',
                        );
                        break;
                    case "EV-PRODUCT-11ST-FAIL-001":
                        mixpanel("evcharge:/evcharge/addcoupon:view.wrongcoupon_popup");
                        logbox("/evcharge/addcoupon", "view.wrongcoupon_popup");

                        modal.alert(
                            () => registPop("wrong"),
                            '유효하지 않은 쿠폰 번호입니다.',
                        );
                        break;
                    default:
                        mixpanel("evcharge:/evcharge/addcoupon:view.wrongcoupon_popup");
                        logbox("/evcharge/addcoupon", "view.wrongcoupon_popup");

                        modal.alert(
                            () => registPop("wrong"),
                            '유효하지 않은 쿠폰 번호입니다.',
                        );
                        break;
                }
            });
    };

    useEffect(() => {
        if (couponType !== 0) {
            registPop("success");
        }
    },[couponType])

    const registPop = (status: any) => {
        switch (status) {
            case "success":
                mixpanel("evcharge:/evcharge/addcoupon:tap.successcoupon_ok");
                logbox("/evcharge/addcoupon", "tap.successcoupon_ok");
                break;
            case "regist":
                mixpanel("evcharge:/evcharge/addcoupon:tap.registcoupon_ok");
                logbox("/evcharge/addcoupon", "tap.registcoupon_ok");
                break;
            case "end":
                mixpanel("evcharge:/evcharge/addcoupon:tap.dateendcoupon_ok");
                logbox("/evcharge/addcoupon", "tap.dateendcoupon_ok");
                break;
            default:
                mixpanel("evcharge:/evcharge/addcoupon:tap.wrongcoupon_ok");
                logbox("/evcharge/addcoupon", "tap.wrongcoupon_ok");
        }

        if (globalVars.queryData.extra?.servicetype === 'couponRegister' || globalVars.queryData.extra?.serviceType === 'couponRegister' || globalVars.queryData.extra?.servicetype === 'couponregister' || globalVars.queryData.extra?.serviceType === 'couponregister') {
            globalVars.queryData.extra.servicetype = '';
            globalVars.queryData.extra.serviceType = '';
            globalVars.queryData.extra.servicetype = '';
            globalVars.queryData.extra.serviceType = '';
        }

        setCouponList([{ couponCode: "" }]);
        
        if (status === "success") {
            if (couponType === 1) {
                goPointCoupon();
            } else {
                goRateCoupon();
            }
        }
    };

    const goPointCoupon = () => {
        props.history.replace('/pointCoupon');
    }
    const goRateCoupon = () => {
        props.history.replace('/rateCoupon');
    }

    // 쿠폰 등록 취소
    const cancel = useCallback(() => {
        setPop(false);
    }, []);

    // couponList로 인해 젠킨스 빌드 시 오류 발생. 확인 필요
    // 쿠폰 등록 확인
    const confirm = useCallback(() => {
        // props.history.push("/pointCoupon");    // 쿠폰목록으로 이동
    }, []);

    const cacheScroll = useCallback(() => {
        // 간헐적으로 상세로 이동 후 호출되는 경우가 있어서 예외처리
        if (!/\/voice\/?$/.test(window.location.pathname)) return;
        scrollCache = window.scrollY;
    }, []);

    useEffect(() => {
        window.scrollTo(0, scrollCache);
        if (globalVars.queryData.extra?.servicetype === 'couponRegister' || globalVars.queryData.extra?.serviceType === 'couponRegister' || globalVars.queryData.extra?.servicetype === 'couponregister' || globalVars.queryData.extra?.serviceType === 'couponregister') {
            setSendCouponCode((globalVars.queryData.extra?.payLoad || globalVars.queryData.extra?.payload) as string)

            if (globalVars.accessKey) {

                if (Const.DBG_OK) {
                    console.log("#2 AccessKey 검증 및 이상없으면 회원정보 리턴");
                }

                getUser()
                    .then(function (response: any) {
                        // 기존회원이므로 회원정보 받기
                        if (response.result) {
                            userData = response;

                            if (Const.DBG_OK) {
                                // console.log(JSON.stringify(response.data));
                                alert("사용자정보 " + JSON.stringify(userData));
                            }

                            // 미등록회원이므로 DB에 등록
                        }
                    })
                    .catch(function (error: any) {
                        if (error.response.status === 400 || error.response.data.result === false) {
                            // 신규회원등록
                            var data = {};

                            postUser(data)
                                .then(function (response: any) {
                                    if (Const.DBG_OK) {
                                        // console.log('신규회원등록')
                                        alert("신규회원등록 " + JSON.stringify(response.data));
                                    }

                                    getUser()
                                        .then(function (response: any) {
                                            userData = response;

                                            if (Const.DBG_OK) {
                                                // console.log('신규회원정보 조회성공')
                                                alert("신규회원 " + JSON.stringify(response.data));
                                            }
                                        })
                                        .catch(function (error: any) {
                                            //							alert('신규회원정보 조회실패')
                                            console.log(error);
                                        });
                                })
                                .catch(function (error: any) {
                                    console.log(error);
                                });
                        }
                    });
            } else {
                if (Const.DBG_OK) {
                    alert("ACCESS KEY 없는경우 - 접속 제한 ()");
                    //props.history.replace("/notFound");
                }
                props.history.replace("/notFound");
            }
        }
        //tmapApi.getUserInfo(params).then(({ data }) => {});
    }, [inited]);

    useEffect(() => {
        window.addEventListener('scroll', cacheScroll);
        return () => window.removeEventListener('scroll', cacheScroll);
    }, [cacheScroll]);

    const resPayLoad = () => {
        if (globalVars.queryData.extra?.payload == undefined) {
            if (globalVars.queryData.extra?.payLoad as string === "") {
                return
            } else {
                return globalVars.queryData.extra?.payLoad
            }
        } else if (globalVars.queryData.extra?.payLoad == undefined) {
            if (globalVars.queryData.extra?.payload as string === "") {
                return
            } else {
                return globalVars.queryData.extra?.payload
            }
        }
    }

    const enterRegist = (e) => {

        if (e.key == 'Enter' || e.keyCode == 13) {
            e.preventDefault();
            e.target.blur();
            // return false;
        }

    };

    return (
        <>
            <Header title={'EV 쿠폰 등록'} isMenu={false} isActive={'3'} />
            {<HandleBrowserBackButton />}

            <div className="coupon-register">
                <div className='coupon-regist-title'>쿠폰 등록</div>
                <div className="register-box">
                    <form>
                        <fieldset style={{ display: "flex" }}>
                            <div className="field">
                                {couponList.map((x, i) => {
                                    return (
                                        <>
                                            <input
                                                type="text"
                                                name="couponCode"
                                                placeholder="쿠폰 코드를 입력해주세요."
                                                value={(globalVars.queryData.extra?.servicetype === 'couponRegister' || globalVars.queryData.extra?.serviceType === 'couponRegister' || globalVars.queryData.extra?.servicetype === 'couponregister' || globalVars.queryData.extra?.serviceType === 'couponregister') ? resPayLoad() as string : x.couponCode}
                                                onChange={e => onChange(e, i)}
                                                onKeyPress={enterRegist}
                                            />
                                        </>
                                    )
                                })}
                            </div>
                            <div className="coupon-add-btn">
                                <button type="button" onClick={registerCoupon} disabled={(globalVars.queryData.extra?.servicetype === 'couponRegister' || globalVars.queryData.extra?.serviceType === 'couponRegister' || globalVars.queryData.extra?.servicetype === 'couponregister' || globalVars.queryData.extra?.serviceType === 'couponregister') ? false : isDisabled}>등록</button>
                            </div>
                        </fieldset>
                    </form>
                </div>
            </div>

            {/* <div className="t-popup" style={{ visibility: pop2 ? 'visible' : 'hidden' }}>
                <div className="t-popupwrap">
                    <div className="t-popupcon">
                        <h3>
                            {popContent}
                        </h3>
                    </div>
                    <div className="t-popup-bottom">
                        <button type="button" className="close-popbutton" onClick={regErrorPop}>확인</button>
                    </div>
                </div>
            </div> */}
        </>
    );
}
export { CouponRegister };