/**-------------------------------------------------------------
-------------------------------------------------------------
> http://basic.pointweb.kr/charge/public/html/public/coupon.html
-------------------------------------------------------------*/
/* eslint-disable */

// 기본
import React, { useState, useEffect, useRef } from 'react';

import { RouteComponentProps, Link } from 'react-router-dom';

//헤더 
import { Header } from '../../components/Layout';
import { HandleBrowserBackButton } from '../../components/Layout'
import { globalVars } from '../../libs/global-vars';
import { tmapInterface } from '../../libs/tmap-interface';

import Const from '../../store/ev';
import { utils } from '../../libs/utils';
import { appActions, store } from '../../store';

//Mixpanel
import { logbox, mixpanel } from '../../libs/logbox-mixpanel';

import Slider from "react-slick";
import { getCoupon, getPoint, patchPointToggle } from '../../apis/coupon';
import { retrieveCardInfo } from '../../libs/commonFunction';
import { getUserStatus } from '../../apis/status';
import { datadogRum } from '../../libs/datadog';
import { modal } from '../../components/GlobalModal';
import { getBanner } from '../../apis/banner';

let userData: any = {};    // 회원정보조회(단건)

function PayMethod(props: RouteComponentProps<{ id: string }>) {

	useEffect(() => {
		mixpanel("evcharge:/evcharge/paymethod");
		logbox("/evcharge/paymethod", "");
	}, [])

	const modalRef = useRef<HTMLDivElement>(null); //모달컴포넌트 바깥쪽 클릭 감지

	useEffect(() => {
		const handleClick = (e: MouseEvent) => {
			if (modalRef.current && !modalRef.current.contains(e.target as Node)) {
				setPointInfo(false);
			}
		};
		window.addEventListener('mousedown', handleClick);
		return () => window.removeEventListener('mousedown', handleClick);
	}, [modalRef]);

	const [payType, setPayType] = useState('N');     // 결제수단 : TODO

	const [payNumber, setPayNumber] = useState(''); // 결제카드 번호
	const [payTypeNm, setPayTypeNm] = useState('');     // 결제수단 명 : TODO
	const [payImageUrl, setPayImageUrl] = useState('');     // 결제수단 이미지 CI
	const [payImageBg, setPayImageBg] = useState('transparent');     // 결제수단 이미지 CI

	const [payKind, setPayKind] = useState(''); // 결제수단 종류
	const [isCard, setIsCard] = useState('NoFocus');
	const [bselectCard, setBselectCard] = useState('');

	const [noValidCoupon, setNoValidCoupon] = useState(false); //포인트 유효
	const [noRateCoupon, setNoRateCoupon] = useState(false); //정률 유효

	const [couponTest, setCouponTest] = useState(false);
	const [pointToggleInfo, setPointToggleInfo] = useState(true);
	const [rateToggleInfo, setRateToggleInfo] = useState(true);

	const [pointToggle, setPointToggle] = useState(false); //전사포인트
	const [pointInfo, setPointInfo] = useState(false);

	const [couponCnt, setCouponCnt] = useState(0);
	const [view, setView] = useState(false);

	const [checkedCoupon, setCheckedCoupon] = useState({ //선택된 쿠폰 
		pointCouponName: "",
		pointCouponValue: 0,
		pointHeadName: "",
		percentCouponName: "",
		percentCouponValue: 0,
		percentHeadName:""
	});
	const [pointInit, setPointInit] = useState(false)

	//포커스 press
	const [focusInfoBtn, setFocusInfoBtn] = useState(false); // 프로모션 배너 포커스
	const [focusPaymentBtn, setFocusPaymentBtn] = useState(false); // 결제수단 포커스
	const [focusPointBtn, setFocusPointBtn] = useState(false); // 포인트 쿠폰 포커스
	const [focusRateBtn, setFocusRateBtn] = useState(false); // 정률 쿠폰 포커스

	const borderBottomStyle = { width: "90%", margin: "0 auto", borderBottom: "1px solid #EEF0F3" };

	const [chargingGuid, setChargingGuid] = useState(false);
	const [userStatusCheck, setUserStatusCheck] = useState(false);
	const [tPayPoint, setTPayPoint] = useState(0);

	const [couponList, setCouponList] = useState([
		{
			// productIdx: 0,
			couponIdx: 0,
			couponHeadName: "",
			couponName: "",
			couponImgNo: 0,
			couponType: 0,
			couponStatus: 0,
			couponAvailableEndDt: "",
			// couponAvailableYn: true,
			pointAmount: 0,
			discountAmount: 0,
			userCheckYn: true
		}
	]);

	const [payCount, setPayCount] = useState(0);

	const [topBannerList, setTopBannerList] = useState<any>([]);
	const [middleBannerList, setMiddleBannerList] = useState<any>([]);

	const getPayMethod = () => {
		const promise = retrieveCardInfo();
		const getData = () => {
			promise.then((result) => {
				if (result) {
					setPayCount(payCount + 1)
				}
			});
		};
		getData();
	}

	const getBannerList = () => {
		getBanner()
			.then((response: any) => {
				if (response.result) {
					const filterTop = response.list.filter((item) => item.targetPage === "PAYMETHOD_TOP").sort((a, b) => (a.bannerSeq - b.bannerSeq));
					const filterMiddle = response.list.filter((item) => item.targetPage === "PAYMETHOD_MIDDLE").sort((a, b) => (a.bannerSeq - b.bannerSeq));
					
					setTopBannerList(filterTop);
					setMiddleBannerList(filterMiddle);
				}
			})
			.catch((error: any) => {
				console.log(error)
			})
	}

	//충전건 있는지 확인하는 함수
	const chkUserStatus = (callback) => {
		getUserStatus()
			.then((response: any) => {
				if (response.result) {
					if (response.chargeSessionGuid) {
						setChargingGuid(true);
						setUserStatusCheck(true);
						callback(true);
					} else {
						setUserStatusCheck(true);
						callback(false);
					}
				}
			})
	}

	useEffect(() => {
		chkUserStatus((res) => {
			if (res) {
				setChargingGuid(true);
			}
		})
	}, []);

	useEffect(() => {
		getPayMethod();
		getBannerList();
	}, []);

	useEffect(() => {
		if (payCount > 0) {
			setPayType(store.getState().app.payType);

			setPayNumber(store.getState().app.payNumber);
			setPayTypeNm(store.getState().app.payTypeNm);
			setPayImageUrl(store.getState().app.payImgUrl);
			setPayImageBg(store.getState().app.payImgBg);

			setPayKind(store.getState().app.payKind);
			setIsCard(store.getState().app.isCard);
			setBselectCard(store.getState().app.BselectCard);
		}
	}, [payCount]);

	useEffect(() => {
		if (userStatusCheck) {
			getCoupon()
				.then((response: any) => {
					//가용포인트가 1이상이면 toggle ON
					// if (tPayPoint > 0) {
					// 	setPointToggle(true);
					// }

					const couponData = response.data;

					const pointToggleInfo = response.pointCouponToggleYn ? response.pointCouponToggleYn : false
					const rateToggleInfo = response.rateCouponToggleYn ? response.rateCouponToggleYn : false

					setCouponCnt(response.data.length);

					if (couponData !== null) {
						setCouponList(couponData);
						setCouponTest(true);
						setPointToggleInfo(pointToggleInfo);
						setRateToggleInfo(rateToggleInfo);
						setView(true);
					}

					const pointChkCoupon = couponData.filter(x => x.couponType === 1 && x.userCheckYn);
					const percentChkCoupon = couponData.filter(x => x.couponType === 2 && x.userCheckYn);

					// couponData.forEach(element => {
					// 	if (element.couponType === 1 && (element.couponStatus !== 3) && element.userCheckYn) { //포인트 체크 쿠폰
					const pointCouponInfo = couponData.filter(x => x.couponType === 1 && (x.couponStatus !== 3) && ((chargingGuid || pointChkCoupon.length === 0) ? {} : x.userCheckYn)) //체크된쿠폰
					const percentCouponInfo = couponData.filter(x => x.couponType === 2 && (x.couponStatus !== 3) && ((chargingGuid || percentChkCoupon.length === 0) ? {} : x.userCheckYn))
	
					setCheckedCoupon({
						...checkedCoupon,
						pointCouponName: pointCouponInfo[0]?.couponName,
						pointCouponValue: pointCouponInfo[0]?.pointAmount,
						pointHeadName: pointCouponInfo[0]?.couponHeadName,
						percentCouponName: percentCouponInfo[0]?.couponName,
						percentCouponValue: percentCouponInfo[0]?.discountAmount,
						percentHeadName: percentCouponInfo[0]?.couponHeadName,
					})
				}).catch((response) => {
					console.log('Error!', response)
				});

				getPoint()
				.then((response: any) => {
					setTPayPoint(response.availablePoint);
					setPointToggle(response.toggleYn);
					setPointInit(true)
				})
				.catch((error) => {
					console.log(error);
				})
		}
	}, [userStatusCheck]);


	useEffect(() => {
		// 유효쿠폰 수 체크
		let pointProduct = couponList.filter(x => x.couponType === 1 && x.couponStatus === 1) //포인트

		if (pointProduct.length > 0) {
			setNoValidCoupon(true);
		}

		let rateProduct = couponList.filter(x => x.couponType === 2 && x.couponStatus === 1)

		if (rateProduct.length > 0) {
			setNoRateCoupon(true);
		}
	}, [couponTest]);

	let couponType; // 포인트/정률 필터링

	const goPointCoupon = () => { //포인트 쿠폰
		mixpanel("evcharge:/evcharge/paymethod:tap.pointcoupon");
		logbox("/evcharge/paymethod", "tap.pointcoupon");

		if (noValidCoupon === false || couponCnt === 0) {
			goCouponRegister("1");
		} else {
			couponType = couponList.filter(x => x.couponType === 1)
			props.history.push(`/pointCoupon`);
			store.dispatch(appActions.setCouponTypeInfo(couponType));
			store.dispatch(appActions.setPointToggleYN(pointToggleInfo));
		}
	}

	const goRateCoupon = () => {
		mixpanel("evcharge:/evcharge/paymethod:tap.discountcoupon");
		logbox("/evcharge/paymethod", "tap.discountcoupon");

		if (noRateCoupon === false || couponCnt === 0) {
			goCouponRegister("2");
		} else {
			couponType = couponList.filter(x => x.couponType === 2)
			props.history.push(`/rateCoupon`);
			store.dispatch(appActions.setCouponTypeInfo(couponType));
			store.dispatch(appActions.setRateToggleYN(rateToggleInfo));
		}
	}

	const goCouponRegister = (productType: string) => {
		props.history.push(`/couponRegister/${productType}`)
	}

	const goPayChoiceView = () => {
		mixpanel("evcharge:/evcharge/paymethod:tap.card");
		logbox("/evcharge/paymethod", "tap.card");

		tmapInterface.startPaymentActivity(selectCard);
	};

	function selectCard(status: string) {
		if (Const.DBG_OK) {
			alert('selectCard \n ' + 'status: ' + status);
		}

		if (globalVars.isIOS) {
			tmapInterface.getAccessKey(getAccessKey);
		} else {
			globalVars.accessKey = tmapInterface.getAccessKey();

			getPayMethod();
			props.history.replace("/payMethod");
		}
	}

	function getAccessKey(value: string) {
		globalVars.accessKey = value;

		getPayMethod();
		props.history.replace("/payMethod");
	}

	var settings = {
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		autoplay: true,
		autoplaySpeed: 3000,
		className: 'slider_custom',
	}

	const goPromotion = (value: string) => {
		switch (value) {
			case "CHARGE_DELIVERY":
				props.history.replace("/deliveryPromotion");
				break;
			case "deliveryfree":
				props.history.replace("/freeDeliveryPromotion");
				break;
			case "TMAP_CARD":
				tmapInterface.openBrowser(
					"tmap://promotion?pageid=plcc_202308"
				);
				break;
			case "KB_PAY":
				tmapInterface.openBrowser(
					"tmap://communication?pageid=eventDetail&seq=52"
				);
				break;
			case "KB_PAY_3":
				tmapInterface.openBrowser(
					"tmap://communication?pageid=eventDetail&seq=59"
				);
				break;
			case "PL_PROMOTION":
				props.history.replace("/plugLinkPromotion");
				break;
			default:
				break;
		}
	}

	const middleBannerLog = () => {
		mixpanel("evcharge:/evcharge/paymethod:tap.kbpay");
		logbox("/evcharge/paymethod", "tap.kbpay");
	}

	const sliderLog = (index: number) => {
		switch (index) {
			case 1:
				mixpanel("evcharge:/evcharge/paymethod:tap.paymethodbanner_detail1");
				logbox("/evcharge/paymethod", "tap.paymethodbanner_detail1");
				break;
			case 2:
				mixpanel("evcharge:/evcharge/paymethod:tap.paymethodbanner_detail2");
				logbox("/evcharge/paymethod", "tap.paymethodbanner_detail2");
				break;
			case 3:
				mixpanel("evcharge:/evcharge/paymethod:tap.paymethodbanner_detail3");
				logbox("/evcharge/paymethod", "tap.paymethodbanner_detail3");
				break;
			default:
				break;
		}
	}

	const getPointInfo = () => { // 전사포인트 유의사항 modal
		return (
			<div className="point-info" ref={modalRef} style={{
				top: Number(window.innerWidth) >= 600 ? "620px" : Number(window.innerWidth) >= 410 ? "475px" : "450px"
			}}
			>
				<div className='title'>
					<span style={{ width: "90%", color: "#51565C" }}>포인트는 전기차충전 서비스 결제 시 전액 자동 사용 됩니다.</span>
					<img src={require('%/images/common/ico_exit.svg').default} alt="" onClick={() => setPointInfo(false)} />
				</div>
				<div className='info-content'>
					<div className='info'>· 최종 결제금액의 3%에 해당되는 금액만 포인트를 사용하실 수 있습니다.</div>
					<div className='info'>· 전체 충전요금에서 포인트가 사용된 차액은 등록하신 결제수단으로 자동 결제 됩니다.</div>
					<div className='info'>· 충전중 화면의 예상 사용 포인트와 충전 종료 시점의 사용 포인트는 다를 수 있습니다.<br />(충전중 포인트 기간 만료, 타 서비스 이용 등 포인트 보유액 변경 상황)</div>
					<div className='info'>· <span className='point-use' onClick={() => payPointActiviry()}>포인트 적립 및 사용 내역 {">"}</span></div>
				</div>
			</div>
		)
	}

	const chgToggleChk = (e) => {
        const toggleYn = e.target.checked;

		chkUserStatus((res) => {
            if (!res) {
				if (tPayPoint === 0) {
					setPointToggle(false);
					tmapInterface.makeToast('보유 포인트가 없습니다.');
				} else {
					if (toggleYn) {
						setPointToggle(true);
						tmapInterface.makeToast('포인트 자동 사용 설정 되었습니다.');
					} else {
						setPointToggle(false);
						tmapInterface.makeToast('포인트 사용 해지 되었습니다.');
					}

				var toggleBody = {
					autoUseType: 3,
					toggleYn: toggleYn
				}

				patchPointToggle(toggleBody)
					.then((response: any) => {
						console.log(toggleBody);
					})
					.catch((error) => {
						console.log(error);
					})
				}
			} else {
				modal.alert(() => {
                }, "변경이 불가합니다.", "충전이 시작되면 포인트 사용 여부를 변경할 수 없습니다. 충전이 종료되고 다시 시도해주세요.");
			}
		})
		
	}

	const payPointActiviry = () => {
		tmapInterface.startPayPointActivity();
	}

	return (
		<>
			<Header title={'전기차충전 결제수단'} isMenu={false} isActive={'3'} />
			<HandleBrowserBackButton />
			<div className="pay-info-img" style={{ paddingTop: "70px", width: "90%", margin: "0 auto", overflowX: "hidden" }}>
				<div className="top">
					<figure>
						<Slider {...settings}>
							{
								topBannerList.map((item, index) => {
									return (
										<div onClick={()=>{goPromotion(item.bannerCode); sliderLog(index+1)}}>
											<img src={require(`%/images/banner/${item.bannerImage}`).default} alt="" style={{padding:"1px"}}/>
										</div>
									)
								})
							}
						</Slider>
					</figure>
				</div>
			</div>
			<div className="pay-info">
				<div className="inner-box">
					<dl className="dl-style1" style={{ padding: window.innerWidth > 500 ? "0 15px 30px" : "" }}>
						<div onClick={() => goPayChoiceView()} onTouchStart={() => { setFocusPaymentBtn(true) }} onTouchEnd={() => { setFocusPaymentBtn(false) }} style={focusPaymentBtn ? { backgroundColor: "#0000001A", borderBottom: "none", display: "flex", position: "relative", alignItems: "center", padding: "20px" } : { borderBottom: "none", display: "flex", position: "relative", alignItems: "center", padding: "20px" }}>
							<dt>결제수단</dt>
							<dd className={isCard} style={{display: "flex", alignItems: "center"}}>
								<span className={payKind === 'PAY' ? "pay-logo-wrapper" : payKind === 'KB_PAY' ?"kb-pay-logo-wrapper":"card-logo-wrapper"}
									  style={{background: payImageBg === '' || payKind === "KB_PAY" ? 'none' : payImageBg, top: payKind === "KB_PAY" ? "2px" : "", position:"relative", marginLeft: "auto"}}>
									{payTypeNm !== '' ? <img src={payImageUrl} alt="" className={payKind === "PAY" ? "pay-logo":payKind === "KB_PAY" ? "kb-pay-logo":"card-logo"} style={{ top: "0" }} /> : ''}
								</span>
								<button type="button" className='noFocus'>
									{Number(window.innerWidth) <= 335
										?
										utils.textLengthOverCut(bselectCard === 'true' ? `${payTypeNm}(${payNumber}), 8, "..."` : bselectCard, 8, "...")
										:
										bselectCard === 'true' ? `${payTypeNm}(${payNumber})` : bselectCard
									}
								</button>
							</dd>
							<img src={require('%/images/common/ico_arrow_right.png').default} alt="" style={{width:"21px", height:"21px"}}/>
						</div>
						{
							middleBannerList.map((item, index) => {
								return (
									<div className="promotion">
										<img src={require(`%/images/banner/${item.bannerImage}`).default} alt="" onClick={() => { goPromotion(item.bannerCode); middleBannerLog();}} />
									</div>
								)
							})
						}
						<div style={borderBottomStyle} />

						<div className="t-point-section">
							<span className="t-point-header">포인트
								<img src={require('%/images/common/ico_question.svg').default} alt="" onClick={() => setPointInfo(true)} className='info-img' />
							</span>
							{pointInit &&(<span className="t-point-body">
								<label className="pay-toggle-control" htmlFor="toggle">
									<input
										type="checkbox"
										className="checked"
										id="toggle"
										onClick={chgToggleChk}
										checked={tPayPoint === 0 ? false : pointToggle}
										readOnly={true}
									/>
									<span className='point-content' style={{ color: pointToggle && tPayPoint !== 0 ? "#0064ff" : "#8C9299" }}>
										{tPayPoint === 0 ? "포인트 없음" : "자동 사용"}
									</span>
									<span className={tPayPoint === 0 ? "uncontrolled" : "control"}></span>
								</label>
								<span className="remainP"> {'보유 ' + utils.comma(Number(tPayPoint)) + 'P'}</span>
							</span>)}
						</div>
						{pointInfo ? getPointInfo() : ""}
						<div style={borderBottomStyle} />

						<div onClick={() => goPointCoupon()} onTouchStart={() => { setFocusPointBtn(true) }} onTouchEnd={() => { setFocusPointBtn(false) }} style={focusPointBtn ? { backgroundColor: "#0000001A", display: "flex", position: "relative", padding: "20px" } : { borderBottom: "none", display: "flex", position: "relative", padding: "20px" }}>
							<dt>선불충전권</dt>
							<dd>
								{view === false ? <></>
									:
									<>
										{noValidCoupon === false || couponCnt === 0 ?
											<button type="button" className='noFocus noCoupon' style={{ marginLeft: "auto" }}>등록하기</button>
											:
											<>
												{!pointToggleInfo
													?
													<>
														<button type="button" className='noFocus' style={{ color: "#8C9299", marginLeft: "auto" }}>사용안함</button>
													</>
													:
													<>
														<button type="button" className='noFocus' style={{ color: "#8C9299", marginLeft: "auto" }}>
															{Number(window.innerWidth) <= 335 && (checkedCoupon.pointHeadName + ' ' + checkedCoupon.pointCouponName).length > 8
																?
																<div className="marquee">{checkedCoupon.pointHeadName + ' ' + checkedCoupon.pointCouponName}</div>
																:
																<>{checkedCoupon.pointHeadName + ' ' + checkedCoupon.pointCouponName}</>
															}
														</button>
														<span className="remainP"> {'잔여 ' + utils.comma(Number(checkedCoupon.pointCouponValue)) + '원'}</span>
													</>
												}
											</>
										}
									</>
								}
							</dd>
							<img src={require('%/images/common/ico_arrow_right.png').default} alt="" style={{width:"21px", height:"21px", top:"2.5px", position:"relative"}}/>
						</div>
						<div style={borderBottomStyle} />

						<div onClick={() => goRateCoupon()} onTouchStart={() => { setFocusRateBtn(true) }} onTouchEnd={() => { setFocusRateBtn(false) }} style={focusRateBtn ? { backgroundColor: "#0000001A", display: "flex", position: "relative", padding: "20px" } : {display: "flex", position: "relative", padding: "20px"}}>
							<dt>할인 쿠폰</dt>
							<dd>
								{view === false ? <></>
									:
									<>
										{noRateCoupon === false || couponCnt === 0 ?
											<>
												<button type="button" className='noFocus noCoupon'>등록하기</button>
											</>
											:
											<>
												{!rateToggleInfo
													?
													<>
														<button type="button" className='noFocus' style={{ color: "#8C9299" }}>사용안함</button>
													</>
													:
													<>
														<button type="button" className='noFocus' style={{ color: "#8C9299" }}>
															{Number(window.innerWidth) <= 335 && (checkedCoupon.percentHeadName + ' ' + checkedCoupon.percentCouponName).length > 8
																?
																<div className="marquee">{checkedCoupon.percentHeadName + ' ' + checkedCoupon.percentCouponName}</div>
																:
																<>{ checkedCoupon.percentHeadName + ' ' + checkedCoupon.percentCouponName }</>
															}
														</button>
														<span className="remainP">{checkedCoupon.percentCouponValue + '% 할인'}</span>
													</>
												}
											</>
										}
									</>
								}
							</dd>
							<img src={require('%/images/common/ico_arrow_right.png').default} alt="" style={{width:"21px", height:"21px", top:"2.5px", position:"relative"}}/>
						</div>
					</dl>
				</div>
			</div>
		</>
	);
}
export { PayMethod };